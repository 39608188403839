@import "common/variables";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower
// GFonts
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700;900&display=swap');
// end Gfonts
@import "common/global";
@import "common/visual_composer";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/single-product";
@import "layouts/woocommerce";
// shortcodes
@import "shortcodes/video-home";
@import "shortcodes/pagetop";
@import "shortcodes/title";
@import "shortcodes/button";
@import "shortcodes/icon-text";
@import "shortcodes/cta";
@import "shortcodes/carousel";
@import "shortcodes/menu";
@import "shortcodes/menu-element";
@import "shortcodes/posts";
@import "shortcodes/single-video";
@import "shortcodes/step-box";
@import "shortcodes/list";
@import "shortcodes/text-highlight";