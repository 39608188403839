.text-highlight {
	display: flex;
	position: relative;
	&__title {
		position: absolute;
		top: 0;
		width: 35%;
		min-width: 350px;
		&__icon {
			position: absolute;
			top: 0;
		}
		&__title,
		&__subtitle {
			font-weight: bold;
			padding: 35px;
			border: 1px solid;
			margin-bottom: 0;
		}
		&__title {
			font-size: 35px;
			color: white;
		}
		&__subtitle {
			font-size: 30px;
			background-color: white;
		}
	}
	&__content {
		width: 70%;
		padding: 50px 70px;
		border: 1px solid;
	}
	&--green {
		.text-highlight__title {
			&__title,
			&__subtitle { border-color: $green; }
			&__title { background-color: $green; }
			&__subtitle { color: $green; }
		}
		.text-highlight__content { border-color: $green; }
	}
	&--dark {
		.text-highlight__title {
			&__title,
			&__subtitle { border-color: $dark; }
			&__title { background-color: $dark; }
			&__subtitle { color: $dark; }
		}
		.text-highlight__content { border-color: $green; }
	}
	&--left {
		justify-content: flex-end;
		.text-highlight__title {
			left: 0;
			text-align: left;
			&__icon {
				left: 0;
				transform: translate(-50%, -50%);
			}
		}
		.text-highlight__content { padding-left: 130px; }
	}
	&--right {
		justify-content: flex-start;
		.text-highlight__title {
			right: 0;
			text-align: right;
			&__icon {
				right: 0;
				transform: translate(50%, -50%);
			}
		}
		.text-highlight__content { padding-right: 130px; }
	}
}

@media (max-width: 1024px) {
	.text-highlight {
		display: block;
		&__title {
			position: relative;
			top: inherit !important;
			left: inherit !important;
			right: inherit !important;
			width: 100%;
			text-align: left !important;
			&__icon {
				left: 0;
				right: inherit !important;
				transform: translate(25%, -50%) !important;
			}
			&__title { font-size: 25px; }
			&__subtitle { font-size: 20px; }
		}
		&__content {
			width: 100%;
			padding: 50px 30px !important;
		}
	}
}