body.single-product main { padding: 0; }

.product-summary {
	display: flex;
    align-items: flex-end;
    background-color: $lightgrey;
    .container {
    	display: flex;
		align-items: flex-end;
		justify-content: flex-end;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__image {
    	position: absolute;
        left: 15px;
        top: 100px;
        width: calc(100% - 80px);
        height: calc(100% - 100px);
        overflow: hidden;
        margin-bottom: 0;
    	img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
    	}
    }
    &__content {
    	z-index: 1;
    	width: 100%;
    	max-width: 500px;
    	padding: 60px 60px 70px 60px;
    	background-color: white;
        .subscription-price {
            font-size: 13px;
            font-weight: 800;
            letter-spacing: 0.05em;
            color: $green;
            margin-bottom: 15px;
            span {
                font-size: 25px;
            }
        }
        .product-in-cart,
        .product-bought,
        .subscription-button {
            display: block;
            position: relative;
            width: 100%;
            font-weight: 800;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: .05em;
            padding: 20px;
            border-radius: 0;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .product-in-cart,
        .product-bought {
            color: $dark;
            border: 1px solid $dark;
            background-color: transparent;
        }
        .subscription-button {
            color: white;
            border: 1px solid $green;
            background-color: $green;
            &:after {
                content: "→";
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
        }
        .normal-price {
            display: flex;
            align-items: baseline;
            font-size: 13px;
            font-weight: 800;
            color: $dark;
        }
        .price {
            font-size: 25px !important;
            font-weight: bold;
            color: $dark !important;
            margin-left: 10px;
        }
    }
}

.product-icon {
	margin-top: 20px;
	margin-bottom: 30px;
	&__item {
	    display: inline-flex;
	    flex-direction: column;
	    align-items: center;
	    margin-right: 30px;
	    svg {
	    	margin-bottom: 10px;
	    }
	}
}

@media (max-width: 900px) {
    .product-summary {
        .container {
            flex-direction: column;
            padding-top: 50px;
        }
        &__image {
            position: unset;
            left: inherit;
            bottom: inherit;
            width: 100%;
            height: 50vw;
        }
        &__content {
            position: unset;
            top: inherit;
            right: inherit;
            width: 100%;
            max-width: inherit;
            height: auto;
            padding: 30px;
        }
    }
    .product-icon {
        &__item {
            margin-right: 20px;
        }
    }
}
@media (max-width: 500px) {
    .product-icon {
        display: flex;
        align-items: baseline;
        justify-content: center;
        flex-wrap: wrap;
    }
    .product-icon__item {
        margin-right: 0;
        flex: 0 1 50%;
        margin-bottom: 15px;
    }
}