// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

div.wpforms-container-full .wpforms-form button[type=submit] {
  width: 100%;
  background-color: $green;
  color: $dark;
  border: none;
  &:hover {
		background-color: $dark;
		color: white;
		border: none;
  }
}

.wpforms-confirmation-container-full {
	background-color:  $green;
	border-color: $green;
	color: white;
}

div.wpforms-container-full .wpforms-form input[type=date],
div.wpforms-container-full .wpforms-form input[type=datetime],
div.wpforms-container-full .wpforms-form input[type=datetime-local],
div.wpforms-container-full .wpforms-form input[type=email],
div.wpforms-container-full .wpforms-form input[type=month],
div.wpforms-container-full .wpforms-form input[type=number],
div.wpforms-container-full .wpforms-form input[type=password],
div.wpforms-container-full .wpforms-form input[type=range],
div.wpforms-container-full .wpforms-form input[type=search],
div.wpforms-container-full .wpforms-form input[type=tel],
div.wpforms-container-full .wpforms-form input[type=text],
div.wpforms-container-full .wpforms-form input[type=time],
div.wpforms-container-full .wpforms-form input[type=url],
div.wpforms-container-full .wpforms-form input[type=week],
div.wpforms-container-full .wpforms-form select,
div.wpforms-container-full .wpforms-form textarea {
  height: auto;
  font-family: "Muli", sans-serif;
  padding: 15px 12px;
  border: 2px solid $green;
}

div.wpforms-container-full .wpforms-form input[type=radio] {
  display: none;
}

.wpforms-field-radio ul li label:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid $green;
  border-radius: 10px;
  margin-right: 8px;
}
.wpforms-field-radio ul li.wpforms-selected label:before {
  background-color: $green;
}