.button__wrapper {
	.button {
		font-weight: bold;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $dark !important;
		background-color: transparent !important;
		transition: color 0.3s ease;
		// &--black:hover {
		// 	color: $dark !important;
		// }
		&--green {
			color: $green !important;
			// &:hover {
			// 	color: $dark !important;
			// }
		}
		&--white {
			color: white !important;
			// &:hover {
			// 	color: $green !important;
			// }
		}
		&:hover {
			text-decoration: none;
			&:after { margin-left: 30px; }
		}
		&:after {
			content: '→';
			display: inline-block;
			margin-left: 20px;
			transition: all 0.3s ease-out;
		}
	}
}