.menu-list {
	&__item {
		margin-bottom: 60px;
		&__img {
			img {
				max-width: 100%;
    			height: auto;
			}
		}
		&__button {
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			color: $dark;
			padding: 25px 30px;
			background-color: white;
			transform: translateY(-100%);
			&:after {
				content: "→";
				display: inline-block;
				margin-left: 15px;
			}
			&:hover {
				color: $green;
				text-decoration: none;
			}
		}
		&__content {
			padding-left: 60px;
			&__title {
				display: block;
				position: relative;
				font-size: $small;
				font-weight: 600;
				color: $green;
				margin: -40px 0 20px 0;
				&:before {
					content: '';
					position: absolute;
					top: calc(50% - 1px);
					left: -45px;
					width: 30px;
					height: 1px;
					background-color: $green;
				}
			}
			&__text {
				font-size: 18px;
				line-height: 25px;
			}
			&__icon {
			    display: flex;
			    align-items: center;
			    justify-content: space-between;
			    flex-wrap: wrap;
			    margin-top: 15px;
			    padding-top: 20px;
			    border-top: 1px solid $green;
			    &__item {
			    	display: flex;
			    	flex-direction: column;
				    align-items: center;
				    flex: 0 1 50%;
				    margin-bottom: 15px;
				    svg { margin-bottom: 5px; }
			    }
			}
		}
	}
}

@media (max-width: 500px) {
	.menu-list {
		&__item {
			&__button { padding-left: 20px; }
			&__content {
				padding-left: 0;
				&__title {
					&:before { display: none; }
				}
			}
		}
	}
}