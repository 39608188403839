.carousel {
	background-color: $green;
	color: white;
	padding: 60px 0 40px 0;
	&__title {
		display: inline-block;
		font-size: 25px;
		font-weight: bold;
		padding: 10px 30px;
		border: 1px solid;
		margin-bottom: 40px;
	}
	&__item {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-bottom: 90px;
		&__number {
		    flex: 0 1 40%;
		    position: relative;
		    font-size: 120px;
		    font-weight: 900;
		    line-height: 150px;
		    letter-spacing: 0.05em;
    		text-align: right;
    		padding-right: 140px;
    		&:after {
				content: '';
				position: absolute;
				right: 30px;
				top: calc(50% - 1px);
				width: 80px;
				height: 2px;
				background-color: white;
    		}
    	}
    	&__content {
    		flex: 0 1 60%;
    		padding-right: 100px;
    		margin-top: 60px;
    		&__title {
    			font-size: 25px;
    			font-weight: bold;
    			letter-spacing: 0.025em;
    			margin-bottom: 30px;
    		}
    		&__text {
    			font-size: 20px;
    			line-height: 30px;
    		}
    	}
	}
	.owl-theme {
		.owl-nav {
			position: absolute;
		    top: 80px;
		    width: 100%;
		    font-size: 45px;
		    margin-top: 0;
		    .owl-prev {
		    	position: absolute;
		    	left: -50px;
		    }
		    .owl-next {
		    	position: absolute;
		    	right: -50px;
		    }
		    .owl-prev.disabled,
		    .owl-next.disabled { opacity: 0; }
		}
		.owl-dots {
			.owl-dot span {
				width: 7px;
				height: 7px;
				background-color: white;
				margin: 3px;
				opacity: 0.5;
			}
			.owl-dot.active span {
				opacity: 1;
			}
		}
	}
}

@media (max-width: 1024px) {
	.carousel {
		&__item {
			&__number {
				text-align: left;
			}
			&__content {
				padding-right: 0;
				flex: 0 1 100%;
			}
		}
		.owl-theme {
			.owl-nav {
				.owl-prev { left: -30px; }
				.owl-next { right: -30px; }
			}
		}
	}
}

@media (max-width: 500px) {
	.carousel {
		.owl-theme {
			.owl-nav { display: none; }
		}
	}
}