//404
.not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-color: $lightgrey;
	span {
		font-size: 150px;
		font-weight: 800;
		letter-spacing: 0.05em;
	}
	h1 {
		font-size: 25px;
		font-weight: bold;
	}
	.button__wrapper {
		margin-top: 30px;
	}
}