.header {
	position: sticky;
	z-index: 500;
	top: 0;
	left: 0;
	width: 100%;
	&__pre {
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		color: white;
		padding: 10px 15px;
		background-color: $green;
		a { color: white; }
	}
	&__content {
		background-color: white;
		.container {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 10px 15px;
		}
		&__left {
		    display: flex;
		    align-items: center;
		    justify-content: flex-start;
		    flex: 0 1 70%;
		    &__logo {
		    	margin-right: 50px;
		    }
		    &__nav {
		    	li {
		    		margin-right: 30px;
		    		a {
			    		color: $dark;
			    		&:hover {
			    			color: $green;
			    			text-decoration: none;
			    		}
			    	}
			    	&.active a {
			    		color: $green;
			    	}
		    	}
		    }
		}
		&__right {
		    display: flex;
		    align-items: center;
		    justify-content: flex-end;
		    flex: 0 1 30%;
		    position: relative;
		    &__login,
		    &__cart {
		    	font-weight: bold;		    	
		    	margin-left: 20px;
		    	cursor: pointer;
		    	a {
		    		display: flex;
		    		align-items: center;
		    		color: $dark;
		    		&:hover {
			    		color: $green;
			    		text-decoration: none;
			    		& svg path { fill: $green; }
			    	}
		    	}
		    	svg { margin-right: 7px; }
		    	&__submenu {
		    		display: none;
		    		position: absolute;
		    		bottom: -10px;
		    		left: 20px;
		    		padding: 15px 15px 25px 15px;
		    		border-top: 2px solid $dark;
		    		background-color: white;
		    		transform: translateY(100%);
		    		a {
		    			display: block;
		    			font-weight: 400;
		    		}
		    		&.active {
		    			display: block;
		    		}
		    	}
		    }
		    &__lang {
		    	font-weight: bold;
			    padding-left: 15px;
			    border-left: 1px solid $green;
			    margin-left: 15px;
			    a {
			    	color: $dark;
			    }
			}
		    .menu-cart {
		    	position: relative;
		    	&__number {
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    position: absolute;
				    top: -3px;
				    left: 10px;
				    font-size: 10px;
				    font-weight: bold;
				    width: 15px;
				    height: 15px;
				    background-color: #E50606;
				    color: white;
				    border-radius: 15px;
		    	}
		    }
		}
	}
}

.widget_shopping_cart_content {
	position: fixed;
	z-index: 600;
	top: 0;
	right: 0;
	height: 100vh;
	padding: 30px 15px;
	background-color: white;
	transform: translateX(100%);
	transition: all 0.4s ease;
	&.active {
		transform: translateX(0%);
	}
}

@media (min-width: 1025px) {
	.header__content .mobile-toggle,
	.header__content .mobile-menu {
		display: none;
	}
}

@media (max-width: 1024px) {
	.header__content .container { padding: 5px 15px; }
	.header__content__left { flex: 0 1 40%; }
	.header__content__left__logo { margin-right: 0; }
	.header__content__left__nav { display: none; }
	.header__content__right { flex: 0 1 60%; }
	.header__content__right__cart,
	.header__content__right__login { margin-left: 25px; }
	.header__content__right__lang {
		padding-left: 20px;
		margin-left: 20px;
	}
	.menu-text { display: none; }
	.header__content__right__cart svg,
	.header__content__right__login svg { margin-right: 0; }
	.mobile-toggle { line-height: 0; }
	.mobile-menu {
		display: none;
	    padding: 5px 15px 20px 15px;
	    &.active { display: block; }
	}
	.mobile__nav .nav { display: block; }
	.mobile__nav .nav li {
		display: block;
		text-align: center;
		margin-bottom: 15px;
	}
	.mobile__nav .nav li a { color: $dark; }
}