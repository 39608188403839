.title {
	h1,h2,h3,h4,h5,h6 { line-height: 1.3em; }
	.medium { font-size: $medium; }
	.big { font-size: $big; }
	.small { font-size: $small; }
	.regular { font-weight: normal; }
	.bold { font-weight: bold; }
	.green { color: $green; }
	.white { color: white; }
	.separator {
		display: inline-block;
		width: 70px;
		height: 2px;
		margin-top: 35px;
		&--dark { background-color: $dark; }
		&--green { background-color: $green; }
		&--white { background-color: white; }
	}
}

@media (max-width: 500px) {
	.medium { font-size: 25px; }
	.big { font-size: 30px; }
	.small { font-size: 20px; }
}