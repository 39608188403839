.posts {
  &__tab {
    text-align: center;
    margin-bottom: 80px;
    &__item {
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      text-transform: capitalize;
      color: $dark;
      padding: 12px 0;
      border: none;
      border-bottom: 1px solid;
      margin: 0 20px;
      background-color: transparent;
      cursor: pointer;
      &:hover,
      &.active {
        color: $green;
        text-decoration: none;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  &__content {
    &__item {
      margin-bottom: 30px;
      &__image {
        display: block;
        width: 100%;
      }
      &__button {
        display: block;
        float: right;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $dark;
        background-color: white;
        padding: 10px 15px;
        transform: translateY(-100%);
        &:hover {
          text-decoration: none;
          color: $green;
        }
      }
      &__date {
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #999999;
        margin-top: 20px;
      }
      &__title {
        font-size: $small;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0.05em;
        margin-top: 15px;
        a { color: $dark; }
      }
    }
  }
  & .posts__content:not(:nth-child(2)) {
    display: none;
  }
}