.menu-element {
	&__top {
		display: flex;
		align-items: baseline;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 100px;
		&__item {
			text-align: center;
			padding: 10px 30px;
			&:not(:last-child) {
				border-right: 1px solid $green;
			}
			&__number {
				font-size: 30px;
				font-weight: 800;
				margin-bottom: 10px;
			}
			&__type {
				font-size: 20px;
			}
		}
	}
	&__bottom {
		&__item {
			&:not(:last-child) {
				margin-bottom: 60px;
			}
			&__carousel {
				padding: 0;
			 	&__viewport {
			 		position: absolute;
			 		top: 0;
			 		left: 0;
			 		width: 100%;
			 		height: 100%;
					.item {
						height: 100%;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					.owl-stage-outer,
					.owl-stage,
					.owl-item{
						height: 100%;
					}
					.owl-nav {
					    position: absolute;
					    top: 50%;
					    left: 0;
					    z-index: 100;
					    width: 100%;
					    .owl-prev,
					    .owl-next {
					    	position: absolute;
						    font-size: 50px !important;
						    color: white !important;
						    line-height: 0 !important;
					    }
					    .owl-prev { left: 15px; }
					    .owl-next { right: 15px; }
					}
					.owl-dots { display: none; }
			 	}
			}
			&__content {
				min-height: 375px;
				padding: 65px 50px 65px 85px;
				background-color: white;
				box-shadow: 0 3px 6px rgba(0,0,0,0.15);
				&__title {
					position: relative;
					font-size: 25px;
					font-weight: 25px;
					color: $green;
					line-height: 30px;
					margin-bottom: 25px;
					&:before {
						content: '';
						position: absolute;
						top: calc(50% - 1px);
						left: -110px;
						width: 85px;
						height: 2px;
						background-color: $green;
					}
				}
				&__text {
					font-size: 18px;
					line-height: 23px;
					margin-bottom: 25px;
				}
				&__list {
					font-size: 18px;
					font-weight: 600;
					line-height: 30px;
					white-space: pre-wrap;
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.menu-element__bottom__item__carousel__viewport {
		position: unset;
		top: inherit;
		left: inherit;
		height: 200px;
	}
	.menu-element__bottom__item__content {
		min-height: inherit;
		padding: 30px;
	}
	.menu-element__bottom__item__content__title:before { display: none; }
}

@media (max-width: 500px) {
	.menu-element__top {
		flex-direction: column !important;
		align-items: center !important;
	}
	.menu-element__top__item {
		border-right: none !important;
	}
}