.pre-footer {
	border-width: 2px 0 2px 0;
	border-color: $dark;
	border-style: solid;
	&__item {
		text-align: center;
		padding: 35px 15px;
		&__title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
		}
		&__image {
			width: auto;
			height: 60px;
			margin-bottom: 20px;
		}
		&__text {
			font-size: 18px;
			white-space: pre-wrap;
		}
		&:not(:last-child) {
			border-right: 2px solid $dark;
		}
	}
}

.footer-payment {
	text-align: center;
	padding: 30px 15px;
	&__item {
		margin: 10px 20px;
	}
}

.footer {
	color: white;
	padding: 85px 15px 25px 15px;
	background-color: $dark;
	&__logo {}
	&__text {
		font-size: 14px;
		line-height: 25px;
	}
	&__social {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		&__item {
			margin-right: 15px;
		}
	}
	&__link {
		font-size: 12px;
		letter-spacing: 0.05em;
		text-align: center;
		margin-top: 70px;
		a {
			color: white;
			text-decoration: none;
			&:hover {
				color: $green;
				text-decoration: none;
			}
		}
	}
}

@media (max-width: 500px) {
	.pre-footer {
		&__item {
			&:not(:last-child) {
				border-right: none;
			}
		}
	}
	.footer {
		padding: 25px 15px;
		text-align: center;
		&__text,
		&__social,
		&__link { margin-top: 30px; }
	}
}