body.woocommerce-account,
body.woocommerce-cart,
body.woocommerce-checkout {
	main {
		margin-top: 50px;
		margin-bottom: 100px;
	}
	h1 {
		font-size: 40px;
		font-weight: bold;
		margin-bottom: 30px;
	}
	h2 {
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	h3 {
		font-size: 25px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 15px;
	}
	.woocommerce form .form-row input.input-text,
	.woocommerce form .form-row textarea {
		padding: 10px 15px;
	}
	.select2-container--default .select2-selection--single {
		border-color: #767676;
	}
	.woocommerce-MyAccount-navigation {
	    padding: 30px 15px;
	    border: 1px solid rgba(0,0,0,.1);
	    border-radius: 3px;
	    ul {
	    	padding-left: 0;
	    	list-style: none;
	    	li {
	    		margin-bottom: 15px;
	    	}
	    }
	}
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-button,
.woocommerce a.button.alt,
.woocommerce button.button.alt {
	background-color: $green;
	color: white;
	&:hover,
	&:focus,
	&:active {
		background-color: $dark;
		color: white;
	}
}

.woocommerce-cart input#coupon_code { width: 150px; }
.woocommerce-cart #pwgc-redeem-gift-card-number {
	padding: 4px 15px;
    width: 220px;
}
.woocommerce-checkout h3#order_review_heading { margin-top: 50px; }
.woocommerce-info { border-top-color: $green; }
.woocommerce-info:before { color: $green; }
.woocommerce-error,
.woocommerce-info,
.woocommerce-message { background-color: $lightgrey; }

.woocommerce table.my_account_orders .button {
	margin-right: 15px;
}
.woocommerce table.shop_table {
	border-collapse: collapse;
}

body.single-product {
	.product-summary__content h1 {
		font-weight: bold;
	}
	.price {
		.subscription-details {
			position: relative;
			top: -10px;
			font-size: 14px;
		}
	}
	button.single_add_to_cart_button.button.alt,
	.button.product-resubscribe-link {
	    width: 100%;
	    font-family: inherit;
	    font-weight: 800;
	    text-align: left;
	    text-transform: uppercase;
	    letter-spacing: 0.05em;
	    color: $dark;
	    padding: 20px;
	    border: 1px solid $dark;
	    border-radius: 0;
	    background-color: transparent;
	    margin-top: 10px;
	    &:after {
		    content: '→';
		    position: absolute;
		    top: 50%;
		    right: 20px;
		    transform: translateY(-50%);
		}
	}
}

@media (max-width: 500px) {
	body.woocommerce-account .woocommerce-MyAccount-navigation {
		margin-bottom: 30px;
	}
}