body {
	font-family: 'Muli', sans-serif;
	text-rendering: geometricPrecision;
}
.container:before,
.container:after,
.row:before,
.row:after { content: normal !important; }
a {
	color: $green;
	&:hover,
	&:focus,
	&:active {
		color: $dark;
		text-decoration: none;
	}
}
p {
	margin-bottom: 0;
}
.owl-theme .owl-nav [class*=owl-]:hover {
	background-color: transparent;
}
.owl-theme .owl-nav [class*=owl-]:focus,
.owl-theme .owl-nav [class*=owl-]:active,
.owl-theme .owl-dots .owl-dot:focus,
.owl-theme .owl-dots .owl-dot:active {
	outline: none;
}
.navigation.pagination {
	width: 100%;
	text-align: center;
	.nav-links {
		width: 100%;
		& > * {
		    margin: 0 10px;
		}
		.page-numbers {
			color: $dark;
			&.current,
			&:hover {
				color: $green;
			}
		}
	}
}
