.list {
	&__wrapper {
		column-count: 2;
		&__item {
			position: relative;
			max-width: 390px;
			padding-left: 60px;
			margin-bottom: 30px;
			break-inside: avoid;
			&__icon {
				position: absolute;
				top: 0;
				left: 0;
				backface-visibility: hidden;
			}
			&__title {
				font-size: 18px;
				font-weight: bold;
				line-height: 30px;
				margin-bottom: 0;
			}
			&__text {
				font-size: 18px;
				line-height: 30px;
			}
		}
	}
}

@media (max-width: 1024px) {
	.list {
		&__wrapper {
			column-count: 1;
			&__item {
				max-width: 100%;
			}
		}
	}
}