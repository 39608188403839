.pagetop {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-top: 100px;
	background-color: $lightgrey;
	&__video,
	&__image {
		display: block;
		width: 100%;
	}
	&__content {
		position: absolute;
		bottom: 0;
		right: 15px;
		width: 50%;
		padding: 30px 0 40px 30px;
		background-color: white;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 25vw;
			height: 100%;
			background-color: white;
			transform: translateX(100%);
		}
		&__title {
			font-size: $big;
			font-weight: 600;
			margin-bottom: 15px;
		}
		&__text {
			font-size: 20px;
			line-height: 25px;
		}
	}
}

@media (max-width: 800px) {
	.pagetop {
		height: auto;
		padding-top: 50px;
		&__content {
			position: relative;
			bottom: 0;
			right: 0;
			width: 100%;
			padding: 30px 0 30px 0;
			background-color: transparent;
			&:after { display: none; }
			&__title { font-size: 30px; }
		}
	}
}