.video-home {
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0 50px;
		margin: 40px 0;
		&__title {
			flex: 0 1 50%;
			font-size: $medium;
			font-weight: bold;
			color: $green;
		}
		&__text {
			flex: 0 1 50%;
			position: relative;
			padding-left: 80px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 40px;
				width: 1px;
				height: 100%;
				background-color: $green;
			}
		}
	}
	&__body {
		position: relative;
		padding: 0 30px;
		&__video {
			display: block;
			width: 100%;
		}
		&__control {
			position: absolute;
			right: 60px;
			bottom: 30px;
			pointer-events: none;
		}
		&__button {
			position: absolute;
			bottom: 0;
			left: 30px;
			font-weight: bold;
		    text-transform: uppercase;
		    letter-spacing: 0.05em;
			padding: 35px 30px 35px 0;
			background-color: white;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 25vw;
				height: 100%;
				transform: translateX(-100%);
				background-color: white;
			}
			&:after {
				content: "→";
				display: inline-block;
				margin-left: 15px;
			}
		}
	}
}

@media (max-width: 500px) {
	.video-home {
		&__header {
			&__title,
			&__text { flex: 0 1 100%; }
			&__text {
				padding-left: 0;
				margin-top: 15px;
				&:before {
					display: none;
				}
			}
		}
		&__body {
			padding: 0;
			&__control {
				right: 30px;
			}
			&__button {
				left: 0;
				padding: 20px;
			}
		}
	}
}