// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
// Colors
$green: #CCC800;
$dark: #1D1D1B;
$lightgrey: #F8F8F8;
// Fonts
$big: 45px;
$medium: 35px;
$small: 22px;