.cta {
	&__image {
		height: 450px;
		background-size: cover;
		background-position: center right;
	}
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $green;
		padding: 50px 15px;
		&__button {
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			background-color: white;
			padding: 25px 50px;
			margin-bottom: 20px;
			&:after {
				content: "→";
				display: inline-block;
				margin-left: 15px;
			}
		}
		&__text {
			font-size: 25px;
			font-weight: 600;
			text-align: center;
			color: white;
		}
	}
}

@media (max-width: 800px) {
	.cta {
		&__image {
			display: none;
		}
	}
}