.step-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex: 0 1 30%;
		position: relative;
		min-height: 330px;
		padding: 30px 50px;
		border-width: 1px 1px 10px 1px;
		border-style: solid;
		margin-bottom: 50px;
		background-color: white;
		&:not(:first-child):before {
			content: '';
			position: absolute;
			top: calc(50% - 7px);
			left: 0;
			width: 0; 
		  	height: 0; 
		  	border-top: 15px solid transparent;
		  	border-bottom: 15px solid transparent;
		  	border-left: 15px solid;
		}
		&__number {
			font-size: 60px;
			font-weight: bold;
			line-height: 60px;
		}
		&__text {
			font-size: 22px;
			font-weight: bold;
		}
	}
	&:after {
		content: '';
		position: absolute;
		top: calc(50% - 21px);
		left: 0;
		z-index: -1;
		width: 100%;
		height: 1px;
	}
	&--green {
		color: $green;
		&:after {
			background-color: $green;
		}
		&__item {
			border-color: $green;
			&:before {
				border-left-color: $green;
			}
		} 
		
	}
	&--dark {
		color: $dark;
		&:after {
			background-color: $dark;
		}
		&__item {
			border-color: $dark;
			&:before {
				border-left-color: $dark;
			}
		}
	}
}

@media (max-width: 900px) {
	.step-box {
		&__item {
			flex: 0 1 100%;
			&:not(:first-child):before {
				display: none;
			}
		}
		&:after {
			display: none;
		}
	}
}